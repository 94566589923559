import React from "react";
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../assets/styles/Main.scss';

function Main() {

  return (
    <div className="container">
      <div className="about-section" id = "about-section">
        <div className="image-wrapper">
          <img src={require("../assets/images/balpreet.jpg")} alt="img" />
        </div>
        <div className="content">
          <div className="social_icons">
            <a href="https://github.com/bjaggi-sag/" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="https://www.linkedin.com/in/balpreet-singh-jaggi-72308471/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
          <h1>Balpreet Jaggi</h1>
          <p>Full Stack Developer</p>
           <p>Experienced Full Stack Developer with 8+ years of experience seeking a challenging role to leverage my expertise in Java, Spring Boot, Angular, and IoT solutions. With a proven track record in full-stack development, I am passionate about building innovative solutions that drive business value. My strong foundation in Java and Spring Boot, combined with proficiency in Angular, allows me to deliver robust and scalable applications.
           </p>

          <div className="mobile_social_icons">
            <a href="https://github.com/bjaggi-sag/" target="_blank" rel="noreferrer"><GitHubIcon/></a>
            <a href="hhttps://www.linkedin.com/in/balpreet-singh-jaggi-72308471/" target="_blank" rel="noreferrer"><LinkedInIcon/></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;