import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Experience</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="Nov 2022 - present"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Engineering Lead</h3>
            <h4 className="vertical-timeline-element-subtitle">Persistent Systems</h4>
            <h4 className="vertical-timeline-element-subtitle">Toronto, Canada</h4>
            <ul>

              <li>Lead development of IoT solutions using Angular, Java Spring Boot, and microservices architecture.</li>
              <li>Architected and deployed backend microservices with REST APIs and Docker for scalable IoT systems.</li>
              <li>Developed secure device agents with C++, AWS SDK, and Paho MQTT CPP for real-time communication.</li>
              <li>Built and maintained Azure CI/CD pipelines for automated deployments.</li>
              <li>Implemented Apama EPL for real-time streaming analytics.</li>
              <li>Mentored junior developers and led a cross-functional team, ensuring adherence to best practices and code quality.</li>
              <li>Led technical discussions with cross-functional teams to align project goals, address challenges, and improve system design and integration.</li>
            
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jul 2018 - Oct 2022"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">IoT Consultant</h3>
            <h4 className="vertical-timeline-element-subtitle">Software Ag</h4>
            <h4 className="vertical-timeline-element-subtitle">Darmstadt, Germany</h4>
            <ul>  
             <li>Developed and deployed microservices using Java Spring Boot and REST APIs for IoT solutions.</li>
             <li>Created IoT device agents using C++ and Java, integrating with libcurl and AWS SDK for data exchange.</li>
             <li>Built and extended web applications using Angular and HTML/CSS, enhancing user interfaces.</li>
             <li>Developed complex event processing applications with Apama EPL for real-time analytics.</li>
             <li>Automated deployments using Azure CI/CD scripts for seamless application updates.</li>
             <li>Conducted unit testing with JUnit5, Mockito, and GUnit to ensure code quality.</li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Sep 2016 - May 2018"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Student Employee</h3>
            <h4 className="vertical-timeline-element-subtitle">Software Ag</h4>
            <h4 className="vertical-timeline-element-subtitle">Darmstadt, Germany</h4>
            <ul>
            <li>Created a comprehensive educational package with tutorials and exercises for university students learning Apama.</li>
            <li>Conducted tutoring sessions to assist enrolled students.</li>
            </ul>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Sep 2014 - Mar 2016"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Software Engineer</h3>
            <h4 className="vertical-timeline-element-subtitle">Aricent Group</h4>
            <h4 className="vertical-timeline-element-subtitle">Gururgram, India</h4>
            <ul>
              <li>Developed and extended multiple desktop applications for clients such as GoPro and ABB.</li>
              <li>Supported the testing team by improving and debugging an internal application for automation test suites.</li>
              <li>Utilized multiple technologies such as C, Java, JavaFx, and C#.</li>
            </ul>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;